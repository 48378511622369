/*
 * @Author: zdl 2694810584@qq.com
 * @Date: 2024-02-28 01:15:49
 * @LastEditors: zdl 2694810584@qq.com
 * @LastEditTime: 2025-01-05 19:36:02
 * @FilePath: /横店/hengdian/src/util/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const isMobile = () => {
  var userAgentInfo = navigator.userAgent;

  // 移动端常见的关键词有"Android", "iPhone", "Windows Phone"等
  var mobileKeywords = ["Android", "iPhone", "Windows Phone"];

  for (var i = 0; i < mobileKeywords.length; i++) {
    if (userAgentInfo.indexOf(mobileKeywords[i]) > -1) {
      return true; // 如果包含任意一个关键词，就认定为移动端
    }
  }

  return false; // 不包含任何关键词，默认为非移动端（PC）
};

const IP = "http://47.99.96.183:8080";
// const IP = "http://121.40.71.24:8080/";
const langs = {
  "zh-cn": "cn",
  en: "en",
};

const langsArr = [
  {
    url: "zh-cn",
    name: "中",
  },
  {
    url: "en",
    name: "EN",
  },
];

const getlang = () => {
  const pathnameArr = window.location.pathname.split("/");
  const key = langsArr.find((k) => {
    return pathnameArr.includes(k?.url);
  });
  return key?.url || "zh-cn";
};

const fetchPost = async (url, param, key = "online") => {
  let formData = { ...param };
  formData["language"] = langs[getlang()];
  try {
    // 设置请求选项，包括请求方法和请求头
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // 如果发送JSON数据
        // 或者如果是表单数据：
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(formData), // 如果是JSON格式
      // 或者如果是表单数据：
      // body: new URLSearchParams(formData).toString()
    };

    const response = await fetch(
      `${IP}/${key}/` + url,
      // "http://121.40.71.24:8080/online/" + url,
      options
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    // console.log("Received data:", data);
    return data;
    // 在这里处理返回的数据...
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

const fetchGet = async (url, param, key = "online") => {
  let formData = { ...param };
  formData["language"] = langs[getlang()];
  // if (formData.newLanguage) {
  //   formData["language"] = formData.newLanguage;
  //   delete formData.newLanguage;
  // } else {
  //   formData["language"] = langs[getlang()];
  // }

  try {
    const paramsUrl = new URLSearchParams("");
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key]; // 编码特殊字符
        // const value = encodeURIComponent(formData[key]); // 编码特殊字符
        paramsUrl.append(key, value); // 添加键值对到 URLSearchParams 对象
      }
    }
    const response = await fetch(
      `${IP}/${key}/` + url + `?${paramsUrl.toString()}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
    // 在这里处理返回的数据...
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

const getMobileUelKey = () => {
  return (isMobile() && "pcUrl") || "webUrl";
};

export { isMobile, getlang, langsArr, fetchPost, fetchGet, getMobileUelKey };
